
ul {
    min-height: fit-content;
}

.main {

    display: flex;

    text-align: center;

    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    background-color: rgb(163, 153, 139);
    font-family: 'Roboto', sans-serif;

}

.content {
    width: 100vw;
    overflow: scroll;
}

.nav {
    display: flex;

    li {
        width: 150px;
        margin: 10px;
    }
}

.navbox {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 100px;
    width: 50%;
    margin: auto;
    margin-top: 50px;

}

.projects-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    li {
        width: 100px;
        padding: 5px 10px;
        font-size: 20px;
    }
}

h1 {
    display: block;
    height: 60px;
    font-size: 60px;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    color: #EADEC7;
}

h2 {
    font-size: 30px;
}


p {
    width: 500px;
    padding: 15px;
    margin: 5px;
}

a:hover {
    color: #EADEC7;
}

a.active {
    color: #EADEC7
}

.detail {
    margin: 10px;
    padding: 10px;
}

.social-icons-list {
    display: flex;
    justify-content: space-between;
    margin: 0px 20px;
    min-height: 70px;
}

.social-icon {

    margin: 10px;

    img {
        height: 50px;
    }
}

.detail {
    width: 100%;
}

.main {
    background-image: url(/backgrounds/ashbackground.png);
    background-size: cover;
    background-position: center;
}

.main.enyo {

    background-color: #333333;
    color: #d3d3d3;

    background-image: url(/backgrounds/enyo.png);
    background-size: cover;

    font-family: 'Roboto', sans-serif;

    h1 {
        font-family: 'Rajdhani', sans-serif;
        color: white;
    }

    h1::before {
        content: '☍';
        color: #bf3f3f;
    }

    .navbox {
        background-color: rgba($color: #333333, $alpha: 1.0);
        border-radius: 20px;
        border: 1px solid lightgray;
    }

    .nav a {

    }

    a:hover {
        color: #bf3f3f;
        font-family: 'Rajdhani', sans-serif;
    }

    a.active {
        color: #bf3f3f;
    }

    a:active {
        color: #f57373;
    }
}

.main.offshoot {
    font-family: monospace;
    background-color: rgba(255,255,255,0.3);
    background-image: url(/backgrounds/offshoot.png);
    background-blend-mode: lighten;
    background-size: 100vw;
    background-position: center bottom;

    h1 {
        font-family: monospace;
        color: #9ABC45;
    }

    a:hover {
        color: #9ABC45;
    }

    a.active {
        color: #9ABC45;
    }

    a:active {
        color: #E1F9A4;
    }

}

.main.vivari {
    background-image: linear-gradient(#232829, black);
    color: #696969;

    font-family: 'Open Sans', sans-serif;

    h1 {
        color: palegoldenrod;
        font-size: 50px;
        font-family: 'Mansalva', cursive;
        text-shadow: 0px 0px 20px rgba(255,254,232,0.6);
    }

    h1::after {
        content: '𖼔';
        margin-left: 10px;
    }

    a:hover {
        color: palegoldenrod;
        text-shadow: 0px 0px 10px rgba(255,254,232,0.6);
        font-family: 'Mansalva', cursive;
    }

    a.active {
        color: palegoldenrod;
        text-shadow: 0px 0px 10px rgba(255,254,232,0.6);
        
    }
    
    a:active {
        color: white;
        text-shadow: 0px 0px 10px white;
    }
}

h3 {
    font-size: 24px;
    margin-bottom: 24px;
}

h4 {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.list {
    display: flex;
    flex-direction: column;
    
    justify-content: space-between;

    li {
        margin: 5px;
    }
    
}

.language {

    h5 {
        font-size: 20px;
        font-weight: bold;
        text-align: left;
    }
    > li {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;
    }
    
    
}

ul.language.list {
    width: 100%;
}

.language > li:nth-child(2n) {
        
    background-color:rgba(119, 111, 97,0.6);
    color:  #EADEC7;
   
}

.skill {
    align-items: flex-end;
    justify-content: space-between;
    > li {
        text-align: right;
    }
}

.listbox {
    display: flex;

    justify-content: space-between;
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

//fallingash

.ashcontainer {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
}

[class^='ashflake'] {
    // height: 10px;
    // width: 10px;
    border-radius: 5px;
    background-color: #B1A79A;
    position: absolute;
    top: -10px;
}

@for $i from 1 to 10 {
    .ashflake#{$i} {
        $size: #{random(5) + 5}px;
        left: percentage(random(100)/100);
        animation: ashfall #{random(7)+7}s linear #{random(10)}s infinite;
        height: $size;
        width: $size;
    }
}

@keyframes ashfall {
    0% {
        top: 0%
    }
    100% {
        top: 100%;
    }
}

//media query

@media only screen and (max-width: 450px) {

    .navbox {
        h1 {
            font-size: 40px;
        }

        h2 {
            font-size: 20px;
        }

        .nav {
            width: 80vw;
        }

        .projects-nav {
            flex-direction: column;
            height: 50vw;
            justify-content: space-between;
        }

        .about-container {

            h3 {
                font-size: 20px;
            }
        }
    }

    .main {
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
    }


}